@media screen and (max-width: 1024px) {
  * {
    box-sizing: border-box;
  }

  html,
  body,
  #root {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }

  body {
    background-color: #f9f7f6;
    font-family: 'proxima';
    color: #4d4d4f;
  }

  @font-face {
    font-family: "proxima";
    src: url("../assets/Fonts/ProximaNova/ProximaNova-Regular.ttf");
  }

  @font-face {
    font-family: "Orpheus";
    src: url("../assets/Fonts/OrpheusPro/OrpheusPro-Medium.ttf");
  }

  @font-face {
    font-family: "Inter";
    src: url("../assets/Fonts/Inter.ttf");
  }

  #head {
    width: 100vw;
    height: 5vh;
    line-height: 5vh;
  }

  #main {
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }

  hr {
    width: 80%;
  }

  #loader {
    position: absolute;
    top: 0vh;
    left: 0vw;
    width: 100vw;
    height: 100vh;
    background-color: #f9f7f6 !important;
    background: url('../../public/assets/images/Kwiat_loader.gif');
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: auto 121px !important;
    z-index: 10000;
  }

  #SmallLoader {
    position: absolute;
    left: 0vw;
    width: 100vw;
    height: 60vh;
    top: 17vh;
    background-position: center !important;
    background-repeat: no-repeat !important;
    z-index: 15;
    background: url('../../public/assets/images/Kwiat_loader.gif');
    background-color: #f9f7f6;
    background-size: auto 121px !important;
  }

  #notification {
    position: absolute;
    top: 2vh;
    right: 25vw;
    width: 35vw;
    height: 5vh;
    background-size: cover;
    background-color: #f9f7f6;
    z-index: 10;
    text-align: center;
    border-radius: 20px;
    color: #4d4d4f;
    line-height: 5vh;
    opacity: 0;
  }

  .fading {
    animation: fadeOut 5s;
  }

  @keyframes fadeOut {
    0% {
      opacity: 0;
    }

    25% {
      opacity: 1;
    }

    75% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  #three {
    position: absolute;
    margin-left: 2.5vw;
    width: 95vw;
    height: 64vh;
    top: 17vh;
    margin-bottom: 5vh;
    border-color: #B8C7D4;
    border-style: solid;
    border-width: 1px;
    background-color: #f9f7f6;
  }

  #threeFullScreen {
    position: absolute;
    margin-left: 2vw;
    width: 95vw;
    height: 90vh;
    margin-top: -2vh;
    margin-bottom: 5vh;
    border-color: #B8C7D4;
    border-style: solid;
    border-width: 1px;
    background-color: #f9f7f6;
  }

  .visible {
    display: block !important;
  }

  .hidden {
    display: none !important;
  }

  #OptionsContainer {
    position: absolute;
    display: inline-block;
    width: 95vw;
    height: 17vh;
    background-color: #f9f7f6;
    margin-left: 2.5vw;
    top: 82vh;
    border-color: #B8C7D4;
    border-width: 1px;
    border-radius: 2px;
    border-style: solid;
  }

  .mainHeading {
    margin-top: 2px;
    color: #4d4d4f;
    font-family: "Orpheus";
    font-size: 24px;
    font-weight: 500;
    display: block;
    margin-left: 0vw;
    width: 100%;
    line-height: 65px;
    text-align: center;
  }

  .heading {
    color: #4d4d4f;
    font-family: "proxima";
    font-size: 15px;
    font-weight: 600;
    display: block;
    width: 100%;
    margin-top: 1vh;
    margin-bottom: 2vh;
    margin-left: 2vw;
    text-align: center;
  }

  .link {
    cursor: pointer;
    font-family: "proxima";
    font-size: 15px;
    font-weight: 600;
    color: #000000;
  }

  .triangle_down1 {
    position: relative;
    top: -5px;
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    border-right: 0.2em solid black;
    border-top: 0.2em solid black;
    transform: rotate(135deg);
    margin-right: 0.5em;
    margin-left: 1.0em;
  }

  #OptionsShapes {
    display: grid;
    text-align: center;
    padding-left: 1vw;
    padding-right: 1vw;
    margin-bottom: 2vh;
    overflow: auto;
    height: 18vh;
    grid-template-columns: repeat(auto-fill, minmax(20vw, 1fr));
    grid-auto-flow: column;
    grid-auto-columns: minmax(20vw, 1fr);
    overflow-x: auto;
  }

  #OptionsShapes::-webkit-scrollbar {
    display: none;
  }

  #OptionsShapes div {
    display: inline-block;
    color: #58585a;
    font-size: 13px;
    font-weight: 400;
    cursor: pointer;
  }

  #OptionsShapes img {
    height: 5vh;
    display: block;
    margin: auto;
    margin-bottom: 10px;
  }

  .Options {
    overflow-y: auto;
    height: auto;
    margin-top: 0vh;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .Options::-webkit-scrollbar {
    display: none;
  }

  #OptionsColor {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: center;
    padding-left: 1vw;
    padding-right: 1vw;
    margin-bottom: 5vh;
    padding-top: 1vh;
  }

  #OptionsColor div {
    display: inline-block;
    color: #58585a;
    font-size: small;

  }

  .circle {
    margin-top: 1vh !important;
    margin-bottom: 1vh !important;
    height: 50px;
    width: 50px;
    display: block;
    margin: auto;
    background-color: #000000;
    border-radius: 50%;
  }

  .gold {
    background-image: linear-gradient(180deg, #4d4d4f6E2 40%, #E0B759 100%)
  }

  .platinum {
    background-image: linear-gradient(180deg, #4d4d4f 40%, #e5e4e2 100%);

  }

  .roseGold {
    background-image: linear-gradient(180deg, #4d4d4f1EA 40%, #FFBB95 100%)
  }


  #Options li {
    display: inline;
    margin-top: 0px;
    border-color: #B8C7D4;
    border-style: solid;
    height: 40px;
    cursor: pointer;
    color: #4d4d4f;
    background-color: #f9f7f6;
    border-radius: 0px;
    border-width: 0px;
    line-height: 20px;
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 40px;
    width: 9vw;
  }

  #Options img {
    display: block;
  }

  .lineHeight {
    line-height: 40px;
    vertical-align: top;
  }

  #Options li:hover {
    background-color: #f9f7f6;
    border-color: #b5c7d6;
  }

  .selected {

    font-size: 15px;
    display: block;
    text-align: center;
    padding-left: 6%;
  }

  .show {
    display: block;
  }

  .hide {
    display: none;
  }

  .rightButtonsTop {
    position: fixed;
    display: block;
    right: 2vw;
    top: 5vh;
    height: 20vh;
    width: 25vw;
  }

  .rightButtonsTop .heading {
    text-align: center;
    margin-bottom: 0px;
    font-size: medium;
    font-weight: 500;
  }

  .rightButtonsTop #OptionsColor {
    margin-top: 0px;
  }

  .rightButtonsBottom {
    position: fixed;
    right: 25vw;
    bottom: 15vh;
    height: 15vh;
    width: 50vw;
    line-height: 15vh;
  }

  .rightButtonsBottom .heading {
    text-align: center;
    margin-bottom: 0px;
    font-size: medium;
    font-weight: 500;
  }

  .rightButtonsBottom #OptionsColor {
    margin-top: 0px;
    margin-left: 2px;
  }

  .rightButtonsBottomExpanded {
    position: fixed;
    display: block;
    right: 12vw;
    bottom: 15vh;
    height: 15vh;
    width: 80vw;
  }

  .rightButtonsBottomExpanded .heading {
    text-align: center;
    margin-bottom: 0px;
    font-size: medium;
    font-weight: 500;
  }

  .rightButtonsBottomExpanded #OptionsColor {
    margin-top: 0px;
  }

  .rightButtonsBottomExpanded #OptionsColor div {
    margin-right: 2vw;
  }

  .progressbar {
    height: 7vh;
    width: 75vw;
    position: absolute;
    display: grid;
    margin-top: 3px;
    margin-left: 5px;
    margin-right: 3px;
    font-weight: 500;
    font-size: 11px !important;
    font-family: "proxima";
    grid-template-columns: repeat(auto-fill, minmax(30vw, 1fr));
    grid-auto-flow: column;
    grid-auto-columns: minmax(30vw, 1fr);
    overflow-x: auto;
  }

  .progressCircle {
    background-color: #b5c7d6;
    border-radius: 50%;
    height: 2vw;
    line-height: 2vw;
    width: 2vw;
    display: block;
    margin: auto;
  }

  .progressStep {
    display: inline-block;
    flex: 0 0 30vw;
    cursor: pointer;
    background-color: #f9f7f6;
    border-radius: 2px;
    margin-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
    color: #4d4d4f;
    text-align: left;
    line-height: 20px;
    overflow-y: hidden;
    border-color: #b5c7d6;
    box-shadow: 0px 0px 6px 0px #00000040;
  }


  .progressStep span {
    display: block;
    text-align: left;
  }

  .progressStepCircle {
    display: inline;
  }

  .progressDash {
    /* position: relative;
    border-color: #B8C7D4;
    display: inline-block;
    margin-top: 2vh;
    margin-left: 1px;
    margin-right: 1px;
    height: 2px;
    width: 5px;
    background-color: #b5c7d6;
    vertical-align: middle;
    line-height: 5vh; */
  }

  .progressStepName {
    height: 40px;
    display: table-cell;
    vertical-align: bottom;
    text-align: center;
  }

  .rightButtonsBottom2 {
    position: fixed;
    display: grid;
    right: 9vw;
    bottom: 5vh;
    height: 20vh;
    width: 6vw;
    text-align: center;
  }

  .rightButtonsBottom2 .heading {
    text-align: center;
    margin-bottom: 0px;
    font-size: medium;
    font-weight: 500;
  }

  .rightButtonsBottom2 #OptionsSize {
    border-radius: 50%;
    background-color: beige;
    height: 25px;
    width: 25px;
    border: #000000;
    text-align: center;
    display: inline-block;
    margin-left: 5px;
    margin-top: 25px;
    cursor: pointer;
  }

  .rightButtonsBottom2 #sizeCurrent {
    display: block;
    text-align: center;
  }

  .rightButtonsTopFullScreen {
    position: fixed;
    display: grid;
    right: 5vw;
    top: 5vh;
    height: 40vh;
    width: 15w;
    background: url('../../public/assets/images/try-on-icon-5.svg');
    background-repeat: no-repeat;
    cursor: pointer;
  }

  .rightButtonsTop {
    position: fixed;
    display: grid;
    right: 7vw;
    top: 20vh;
    height: 40vh;
    width: 15vw;
    background: url('../../public/assets/images/try-on-icon-5.svg');
    background-repeat: no-repeat;
    cursor: pointer;
  }

  .Disclaimer {
    position: fixed;
    display: none;
    left: 3.5vw;
    bottom: 2vh;
    width: 28vw;
    font-family: "proxima";
    font-weight: 200;
    color: #58585a;
    font-size: 11px;
    text-align: justify;
    text-justify: inter-word;
  }

  .rightButtonsTop .heading {
    text-align: center;
    margin-bottom: 0px;
    font-size: medium;
    font-weight: 500;
    width: 10vw;
  }

  .rightButtonsTop #OptionsSize {
    height: 40px;
    width: 10vw;
    text-align: center;
    vertical-align: middle;
    line-height: 40px;
    display: block;
    cursor: pointer;
  }

  .leftButtonsBottom {
    position: fixed;
    display: inline-block;
    right: 5vw;
    top: 14vh;
    cursor: pointer;
    height: 5vh;
    width: 5vw;
    background: url('../../public/assets/images/mail.png');
    background-repeat: no-repeat;
    background-size: 5vw 5vw;
    cursor: pointer;
  }

  .leftButtonsBottom2 {
    position: fixed;
    display: inline-block;
    right: 15vw;
    top: 14vh;
    cursor: pointer;
    height: 5vh;
    width: 5vw;
    background: url('../../public/assets/images/link.png');
    background-repeat: no-repeat;
    background-size: 5vw 5vw;
    cursor: pointer;
  }

  .TopButton2 {
    position: fixed;
    display: inline-block;
    right: 5vw;
    top: 9vh;
    height: 5vh;
    width: 20vw;
    cursor: pointer;
    line-height: 5vh;
    text-align: right;
    vertical-align: middle;
    color: #f9f7f6;
    font-weight: 600;
    font-size: 13px;
    font-family: "proxima";
  }

  .priceContainer {
    position: fixed;
    display: inline-block;
    left: 50vw;
    bottom: 10vh;
    width: 15vw;
    transform: translateX(-50%);
  }

  .price {
    font-weight: 100;
    font-size: x-large;
  }

  #modalBackground {
    width: 100%;
    height: 100%;
    position: absolute;
    display: block;
    background-color: rgba(20, 20, 20, 0.4);
    top: 0;
    left: 0;
    z-index: 100;
  }

  #modalWindow {
    width: 90vw;
    height: 95vh;
    position: fixed;
    display: block;
    background-color: rgba(255, 255, 255, 0.95);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    border-color: #B8C7D4;
    border-style: solid;
    border-width: 2px;
    box-shadow: 0px 0px 6px 0px #00000040;
  }

  #modalHeading {
    position: relative;
    height: 80px;
    width: 100%;
    background-color: #f9f7f6;
    color: #4d4d4f;
    text-align: center;
    line-height: 80px;
    vertical-align: middle;
    font-family: "Orpheus" !important;
    font-size: 22px !important;
  }

  #labels {
    display: inline-block;
    height: 100%;
    width: 22vw;
    vertical-align: top;
    font-family: "proxima" !important;
    padding-top: 5vh;
    padding-left: 5vw;
  }

  #labels li {
    height: 30px;
    line-height: 30px;
    vertical-align: middle;
    text-align: left;
    list-style: none;
  }

  #labels ul {
    width: 0px !important;
  }

  #formFields ul {
    width: 80vw;
  }

  #formFields {
    display: inline-block;
    height: 100%;
    width: 66vw;
    font-family: "proxima" !important;
    padding-top: 5vh;
    padding-left: 5vw;
  }

  #formFields li {
    height: 30px;
    line-height: 30px;
    vertical-align: middle;
    text-align: left;
    list-style: none;
  }

  #formFields input {
    font-family: 'proxima';
    width: 80%;
    font-size: 14pt;
  }

  #formFields textarea {
    width: 80%;
    height: 200px;
    padding: 10px;
    font-size: 14pt;
  }

  .error {
    color: red;
    margin-top: -5px;
    font-size: 8pt;
  }

  #modalFooter {
    width: 100%;
    height: 150px;
    margin-top: 3vh;
  }

  #cancelButton {
    margin-left: 30%;
    height: 50px;
    width: 20%;
    background-color: lightgray;
    color: #000;
    border-radius: 10px;
    border-color: #b5c7d6;
    box-shadow: 0px 0px 6px 0px #00000040;
    line-height: 50px;
    vertical-align: middle;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    font-family: "proxima";
    display: inline-block;
    cursor: pointer;
  }

  #submitButton {
    margin-left: 5%;
    height: 50px;
    width: 20%;
    background-color: #B8C7D4;
    color: #4d4d4f;
    border-radius: 2px;
    border-color: #4D4D4F;
    box-shadow: 0px 0px 6px 0px #00000040;
    line-height: 50px;
    vertical-align: middle;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    font-family: "proxima" !important;
    display: inline-block;
    cursor: pointer;
  }
}


@media screen and (orientation: landscape) and (max-device-height:500px) {
  * {
    box-sizing: border-box;
  }

  html,
  body,
  #root {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }

  body {
    background-color: #f9f7f6;
    font-family: 'proxima';
    color: #4d4d4f;
  }

  @font-face {
    font-family: "proxima";
    src: url("../assets/Fonts/ProximaNova/ProximaNova-Regular.ttf");
  }

  @font-face {
    font-family: "Orpheus";
    src: url("../assets/Fonts/OrpheusPro/OrpheusPro-Medium.ttf");
  }

  @font-face {
    font-family: "Inter";
    src: url("../assets/Fonts/Inter.ttf");
  }

  #head {
    width: 100vw;
    height: 5vh;
    line-height: 5vh;
  }

  #main {
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }

  hr {
    width: 80%;
  }

  #loader {
    position: absolute;
    top: 0vh;
    left: 0vw;
    width: 100vw;
    height: 100vh;
    background-color: #f9f7f6 !important;
    background: url('../../public/assets/images/Kwiat_loader.gif');
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: auto 121px !important;
    z-index: 10000;
  }

  #SmallLoader {
    position: absolute;
    left: 0vw;
    width: 100vw;
    height: 60vh;
    top: 17vh;
    background-position: center !important;
    background-repeat: no-repeat !important;
    z-index: 15;
    background: url('../../public/assets/images/Kwiat_loader.gif');
    background-color: #f9f7f6;
    background-size: auto 121px !important;
  }

  #notification {
    position: fixed;
    top: 5vh;
    right: 25vw;
    width: 30vw;
    height: 10vh;
    background-size: cover;
    background-color: #f9f7f6;
    z-index: 10;
    text-align: center;
    border-radius: 20px;
    color: #4d4d4f;
    line-height: 10vh;
    opacity: 0;
  }

  .fading {
    animation: fadeOut 5s;
  }

  @keyframes fadeOut {
    0% {
      opacity: 0;
    }

    25% {
      opacity: 1;
    }

    75% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  #three {
    position: absolute;
    margin-left: 2.5vw;
    width: 75vw;
    height: 60vh;
    top: 17vh;
    margin-bottom: 5vh;
    border-color: #B8C7D4;
    border-style: solid;
    border-width: 1px;
    background-color: #f9f7f6;
    right: 1.0vw;
  }

  #threeFullScreen {
    position: absolute;
    margin-left: 2vw;
    width: 95vw;
    height: 90vh;
    margin-top: -2vh;
    margin-bottom: 5vh;
    border-color: #B8C7D4;
    border-style: solid;
    border-width: 1px;
    background-color: #f9f7f6;
  }

  .visible {
    display: block !important;
  }

  .hidden {
    display: none !important;
  }

  #OptionsContainer {
    position: absolute;
    display: inline-block;
    width: 75vw;
    height: 21vh;
    background-color: #f9f7f6;
    margin-left: 2.5vw;
    top: 78vh;
    border-color: #B8C7D4;
    border-width: 1px;
    border-style: solid;
    border-radius: 2px;
    right: 1.0vw;
  }

  .mainHeading {
    margin-top: 2px;
    color: #4d4d4f;
    font-family: "Orpheus";
    font-size: 24px;
    font-weight: 500;
    display: block;
    margin-left: 1vw;
    width: 100%;
    line-height: 65px;
    text-align: left;
  }

  .heading {
    color: #4d4d4f;
    font-family: "proxima";
    font-size: 12px;
    font-weight: 600;
    display: block;
    width: 100%;
    margin-top: 0vh;
    margin-bottom: 2vh;
    margin-left: 2vw;
    text-align: center;
  }

  .link {
    cursor: pointer;
    font-family: "proxima";
    font-size: 15px;
    font-weight: 600;
    color: #000000;
  }

  .triangle_down1 {
    position: relative;
    top: -5px;
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    border-right: 0.2em solid black;
    border-top: 0.2em solid black;
    transform: rotate(135deg);
    margin-right: 0.5em;
    margin-left: 1.0em;
  }

  #OptionsShapes {
    display: grid;
    text-align: center;
    padding-left: 1vw;
    padding-right: 1vw;
    margin-bottom: 2vh;
    overflow: auto;
    height: 20vh;
    grid-template-columns: repeat(auto-fill, minmax(25vw, 25vw, 2fr));
    grid-auto-flow: column;
    grid-auto-columns: minmax(25vw, 25vw, 2fr);
    overflow-x: auto;

  }

  #OptionsShapes::-webkit-scrollbar {
    display: none;
  }

  #OptionsShapes div {
    display: inline-block;
    color: #58585a;
    font-size: 13px;
    font-weight: 400;
    cursor: pointer;
  }

  #OptionsShapes img {
    height: 10vh;
    display: block;
    margin: auto;
  }

  .Options {
    overflow-y: auto;
    height: auto;
    margin-top: 0vh;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .Options::-webkit-scrollbar {
    display: none;
  }

  #OptionsColor {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: center;
    padding-left: 1vw;
    padding-right: 1vw;
    margin-bottom: 5vh;
    padding-top: 1vh;
  }

  #OptionsColor div {
    display: inline-block;
    color: #58585a;
    font-size: small;

  }

  .circle {
    margin-top: 1vh !important;
    margin-bottom: 1vh !important;
    height: 50px;
    width: 50px;
    display: block;
    margin: auto;
    background-color: #000000;
    border-radius: 50%;
  }

  .gold {
    background-image: linear-gradient(180deg, #4d4d4f6E2 40%, #E0B759 100%)
  }

  .platinum {
    background-image: linear-gradient(180deg, #4d4d4f 40%, #e5e4e2 100%);

  }

  .roseGold {
    background-image: linear-gradient(180deg, #4d4d4f1EA 40%, #FFBB95 100%)
  }


  #Options li {
    display: inline;
    margin-top: 0px;
    border-color: #B8C7D4;
    border-style: solid;
    height: 40px;
    cursor: pointer;
    color: #4d4d4f;
    background-color: #f9f7f6;
    border-radius: 0px;
    border-width: 0px;
    line-height: 20px;
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 40px;
    width: 9vw;
  }

  #Options img {
    display: block;
  }

  .lineHeight {
    line-height: 40px;
    vertical-align: top;
  }

  #Options li:hover {
    background-color: #f9f7f6;
    border-color: #b5c7d6;
  }

  .selected {

    font-size: 15px;
    display: block;
    text-align: center;
    padding-left: 6%;
  }

  .show {
    display: block;
  }

  .hide {
    display: none;
  }

  .rightButtonsTop {
    position: fixed;
    display: block;
    right: 2vw;
    top: 5vh;
    height: 20vh;
    width: 25vw;
  }

  .rightButtonsTop .heading {
    text-align: center;
    margin-bottom: 0px;
    font-size: medium;
    font-weight: 500;
  }

  .rightButtonsTop #OptionsColor {
    margin-top: 0px;
  }

  .rightButtonsBottom {
    position: fixed;
    left: 22vw;
    top: 15vh;
    height: 15vh;
    width: 20vw;
    line-height: 15vh;
    text-align: left;
  }

  .rightButtonsBottom .heading {
    text-align: center;
    margin-bottom: 0px;
    font-size: medium;
    font-weight: 500;
  }

  .rightButtonsBottom #OptionsColor {
    margin-top: 0px;
    margin-left: 2px;
  }

  .rightButtonsBottomExpanded {
    position: fixed;
    display: block;
    left: 25vw;
    top: 17vh;
    height: 15vh;
    width: 20vw;
  }

  .rightButtonsBottomExpanded .heading {
    text-align: center;
    margin-bottom: 0px;
    font-size: medium;
    font-weight: 500;
  }

  .rightButtonsBottomExpanded #OptionsColor {
    margin-top: 0px;
  }

  .rightButtonsBottomExpanded #OptionsColor div {
    margin-right: 2vw;
  }

  .progressbar {
    height: 80vh;
    width: 20vw;
    position: absolute;
    margin-top: 3px;
    margin-left: 5px;
    margin-right: 3px;
    font-weight: 500;
    font-size: 11px !important;
    font-family: "Inter" !important;
    display: flex;
    flex-direction: column;
  }

  .progressCircle {
    background-color: #b5c7d6;
    border-radius: 50%;
    height: 2vw;
    line-height: 2vw;
    width: 2vw;
    display: block;
    margin: auto;
  }

  .progressStep {
    cursor: pointer;
    background-color: #f9f7f6;
    border-radius: 2px;
    color: #4d4d4f;
    text-align: left;
    line-height: 20px;
    border-color: #b5c7d6;
    box-shadow: 0px 0px 6px 0px #00000040;
    flex: 1;
    margin: 10px;
  }


  .progressStep span {
    display: block;
    text-align: left;
  }

  .progressStepCircle {
    display: inline;
  }

  .progressDash {
    /* position: relative;
    border-color: #B8C7D4;
    display: inline-block;
    margin-top: 2vh;
    margin-left: 1px;
    margin-right: 1px;
    height: 2px;
    width: 5px;
    background-color: #b5c7d6;
    vertical-align: middle;
    line-height: 5vh; */
  }

  .progressStepName {
    height: 40px;
    display: table-cell;
    vertical-align: bottom;
    text-align: center;
  }

  .rightButtonsBottom2 {
    position: fixed;
    display: grid;
    right: 9vw;
    bottom: 5vh;
    height: 20vh;
    width: 6vw;
    text-align: center;
  }

  .rightButtonsBottom2 .heading {
    text-align: center;
    margin-bottom: 0px;
    font-size: medium;
    font-weight: 500;
  }

  .rightButtonsBottom2 #OptionsSize {
    border-radius: 50%;
    background-color: beige;
    height: 25px;
    width: 25px;
    border: #000000;
    text-align: center;
    display: inline-block;
    margin-left: 5px;
    margin-top: 25px;
    cursor: pointer;
  }

  .rightButtonsBottom2 #sizeCurrent {
    display: block;
    text-align: center;
  }

  .rightButtonsTopFullScreen {
    position: fixed;
    display: grid;
    right: 5vw;
    top: 5vh;
    height: 40vh;
    width: 15w;
    background: url('../../public/assets/images/try-on-icon-5.svg');
    background-repeat: no-repeat;
    cursor: pointer;
  }

  .rightButtonsTop {
    position: fixed;
    display: grid;
    right: 2vw;
    top: 20vh;

    width: 10vw;
    background: url('../../public/assets/images/try-on-icon-5.svg');
    background-repeat: no-repeat;
    cursor: pointer;
  }

  .Disclaimer {
    position: fixed;
    display: none;
    left: 3.5vw;
    bottom: 2vh;
    width: 28vw;
    font-family: "proxima";
    font-weight: 200;
    color: #58585a;
    font-size: 11px;
    text-align: justify;
    text-justify: inter-word;
  }

  .rightButtonsTop .heading {
    text-align: center;
    margin-bottom: 0px;
    font-size: medium;
    font-weight: 500;
    width: 10vw;
  }

  .rightButtonsTop #OptionsSize {
    height: 40px;
    width: 10vw;
    text-align: center;
    vertical-align: middle;
    line-height: 40px;
    display: block;
    cursor: pointer;
  }

  .leftButtonsBottom {
    position: fixed;
    display: inline-block;
    right: 15vw;
    top: 5vh;
    cursor: pointer;
    height: 8vh;
    width: 4vw;
    background: url('../../public/assets/images/mail.png');
    background-repeat: no-repeat;
    background-size: 4vw 4vw;
    cursor: pointer;
  }

  .leftButtonsBottom2 {
    position: fixed;
    display: inline-block;
    right: 22vw;
    top: 5vb;
    cursor: pointer;
    height: 3vw;
    width: 3vw;
    background: url('../../public/assets/images/link.png');
    background-repeat: no-repeat;
    background-size: 3vw 3vw;
    cursor: pointer;
  }

  .TopButton2 {
    position: fixed;
    display: inline-block;
    right: 5vw;
    top: 7vh;
    height: 5vh;
    width: 8vw;
    cursor: pointer;
    line-height: 5vh;
    text-align: right;
    vertical-align: middle;
    color: #f9f7f6;
    font-weight: 600;
    font-size: 13px;
    font-family: "Inter" !important;
  }

  .priceContainer {
    position: fixed;
    display: inline-block;
    left: 50vw;
    bottom: 10vh;
    width: 15vw;
    transform: translateX(-50%);
  }

  .price {
    font-weight: 100;
    font-size: x-large;
  }

  #modalBackground {
    width: 100%;
    height: 100%;
    position: absolute;
    display: block;
    background-color: rgba(20, 20, 20, 0.4);
    top: 0;
    left: 0;
    z-index: 100;
  }

  #modalWindow {
    width: 90vw;
    height: 95vh;
    position: fixed;
    display: block;
    background-color: rgba(255, 255, 255, 0.95);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    border-color: #B8C7D4;
    border-style: solid;
    border-width: 2px;
    box-shadow: 0px 0px 6px 0px #00000040;
    overflow-y: scroll;
  }

  #modalHeading {
    position: relative;
    height: 80px;
    width: 100%;
    background-color: #f9f7f6;
    color: #4d4d4f;
    text-align: center;
    line-height: 80px;
    vertical-align: middle;
    font-family: "Orpheus" !important;
    font-size: 22px !important;
  }

  #labels {
    display: inline-block;
    height: 100%;
    width: 22vw;
    vertical-align: top;
    font-family: "proxima" !important;
    padding-top: 5vh;
    padding-left: 5vw;
  }

  #labels li {
    height: 30px;
    line-height: 30px;
    vertical-align: middle;
    text-align: left;
    list-style: none;
  }

  #labels ul {
    width: 0px !important;
  }

  #formFields ul {
    width: 80vw;
  }

  #formFields {
    display: inline-block;
    height: 100%;
    width: 66vw;
    font-family: "proxima" !important;
    padding-top: 5vh;
    padding-left: 5vw;
  }

  #formFields li {
    height: 30px;
    line-height: 30px;
    vertical-align: middle;
    text-align: left;
    list-style: none;
  }

  #formFields input {
    font-family: 'proxima';
    width: 80%;
    font-size: 14pt;
  }

  #formFields textarea {
    width: 80%;
    height: 200px;
    padding: 10px;
    font-size: 14pt;
  }

  .error {
    color: red;
    margin-top: -5px;
    font-size: 8pt;
  }

  #modalFooter {
    width: 100%;
    height: 150px;
    margin-top: 3vh;
  }

  #cancelButton {
    margin-left: 30%;
    height: 50px;
    width: 20%;
    background-color: #f9f7f6;
    color: #4d4d4f;
    line-height: 50px;
    vertical-align: middle;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    font-family: "proxima" !important;
    display: inline-block;
    cursor: pointer;
  }

  #submitButton {
    margin-left: 5%;
    height: 50px;
    width: 20%;
    background-color: #B8C7D4;
    color: #4d4d4f;
    border-radius: 2px;
    border-color: #4D4D4F;
    box-shadow: 0px 0px 6px 0px #00000040;
    line-height: 50px;
    vertical-align: middle;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    font-family: "proxima" !important;
    display: inline-block;
    cursor: pointer;
  }
}